import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

const SliderContainer = ({ children, settings }) => {
    return (
        <div className="slider-container">
            <div className="slider-wrapper">
                <div className="inner">
                    <Slider {...settings}>
                        {children}
                    </Slider>
                </div>
            </div>
        </div>
    );
};

SliderContainer.propTypes = {
    settings: PropTypes.object,
    children: PropTypes.node,
};

SliderContainer.defaultProps = {
    settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true
    }
};

export default SliderContainer;
